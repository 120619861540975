<template>
  <base-layout
    show-back-btn="true"
    page-default-back-link="/"
    show-info="true"
  >
    <h2>Information</h2>
  </base-layout>
</template>

<script>
export default {};
</script>
